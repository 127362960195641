// @use "../système_design_components/bigCarousel/bigCarousel" ;
@import 'gaeaHeader';
@import 'gaeaMaterial';
@import "../function";

@media only screen and (min-width: 301px) {
  div.container {
    width: 70%;
  }
}
@media only screen and (min-width: 601px) {
  div.container {
    width: 70%;
  }
}

@media only screen and (min-width: 993px) {
  div.container {
    width: 70%;
  }
}

/* Global Style */
  *{
    margin:0;
    padding: 0;
  }
  html{
    width: 100vw;
    overflow-x: hidden;
  }
  body{
    width: 100vw;
    font-family: 'Roboto', sans-serif;
    li {
      list-style-type: none;
    }
  }
  /* You can add global styles to this file, and also import other style files */
  * {
    border-radius: 1px rgb(41, 46, 46);
    font-size: 1em;
  }
  a{
    color: inherit !important;
    text-decoration: none !important;
  }
/* */

/* Scrollbar */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgb(118, 126, 4) white;
  }

  /*  scrollbar  pour les autres navigateurs */
  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  *::-webkit-scrollbar-track {
    background: rgb(35, 38, 41);
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgb(111, 148, 10);
    border-radius: 20px;
    border: 1px solid rgb(36, 39, 39);
  }
/* */

#section1 {
  height: pxToVw(24px, 1920px);
}
#section2{
  padding: 0;
  padding-left: 15%;
  padding-right: 15%;
}
#section3{
}
#section4{

}

#deco1 {
  height: 1.5vw;
  left:1.3vw;
  top:14.8vw;
  transform: translate(4vw,0);
}
#deco2{
}
#deco3{
}
#deco4{
}

#section4 .statisticBackground{
  height: 15vw;
  width: 15vw;
  font-size: pxToVw(24px, 1920px);
  background: url("../../../public/images/homepage/statistique.jpg");

  &:hover span{
  color: white !important;
    background-size: cover;
    background-position: center;
  }
}


#section4 .politicBackground{

  height: 15vw;
  width: 15vw;
  font-size: pxToVw(24px, 1920px);

  background: url('../../../public/images/homepage/politique.jpg');
  background-size: cover;
  background-position: center;

  &:hover span{
  color: white !important;
  background-size: cover;
  background-position: center
  }
}


#section4 .actionProgramBackground{

  height: 15vw;
  width: 15vw;
  font-size: pxToVw(24px, 1920px);

  background:url('../../../public/images/homepage/action-programme.jpg');
  background-size: cover;
  background-position: center;

  &:hover span{
  color: white !important;
  background-size: cover;
  background-position: center;
  }
}


#sectionNewSite{
  position: relative;
  font-family: "Roboto", sans-serif;
  color: black;
  text-wrap: wrap;
  font-size: pxToVw(16px, 1920px);
  h3{
    font-family: 'Alfa Slab One';
    font-size: pxToVw(28px, 1920px);
  }

  button{
    position: absolute;
    bottom: -pxToVw(67px, 1920px);
    right: pxToVw(20px, 1920px);
    color:  white;
    background-color: #007263;
    width: pxToVw(150px, 1920px);
    height: pxToVw(40px, 1920px);
    border: none;
    border-radius: pxToVw(33px, 1920px);
    p{
      margin: 0px;
      &:nth-of-type(1){
        display: block;
      }
      &:nth-of-type(2){
        display: none;
      }
    }
  }
  button:hover{
    background-color: #40958A;
    p{
      &:nth-of-type(1){
        display: none;
      }
      &:nth-of-type(2){
        display: block;
      }
    }
  }
}


#sectionBlogNewletter{
  font-family: "Roboto";
  font-size: pxToVw(16px, 1920px);
  color: black;
margin-bottom: 2vw;
  #sectionBlogNewletter1{
    font-family: "Roboto", sans-serif;
    h3{
      font-weight: bold;
      font-family: "Alfa Slab One";
      color: #98984B;
      font-size: pxToVw(28px, 1920px);
    }
    p:nth-child(2){
      font-size: pxToVw(16px, 1920px);

    }
  }
  #sectionBlogNewletter2{
    margin-top: pxToVw(100px, 1920px);

    #divOffres{
      display: flex;
      flex-direction: row;
      div{
        margin-top: pxToVw(5px, 1920px);
        display: flex;
        flex-direction: column;
        div{
            flex-direction: row;
        }
        strong{
          font-weight: bold;
          line-height: 2vw;
        }
      }
    }
    img{
      width: pxToVw(45px, 1920px);
      height: pxToVw(69px, 1920px);
      margin-bottom: pxToVw(23px, 1920px);
      margin-right: pxToVw(50px, 1920px);
      &:nth-of-type(1){
        margin-top: 30px;
      }
    }
  }

  #sectionBlogNewletter3{


    #divOffres{
      display: flex;
      flex-direction: row;
      div{
        display: flex;
        flex-direction: column;
        div{
            flex-direction: row;
        }
        strong{
          font-weight: bold;
          line-height: 2vw;
        }
      }
    }
    img{
      width: pxToVw(60px, 1920px);
      height: pxToVw(59px, 1920px);
      margin-bottom: pxToVw(23px, 1920px);
      margin-right: pxToVw(50px, 1920px);
      &:nth-of-type(1){
        margin-top: pxToVw(6px, 1920px);
      }
    }
  }
}

/*.section {
  background-image: url('../../imagesgaea21/EARTH3.jpg');
  background-size: cover;
  background-position: center;
  min-height: 500px;
}*/
.sectionFirst {
  background-image: url('../../../public/imagesgaea21/field-176602_1920.jpg');
  background-size: cover;
  background-position: center;
  min-height: 500px;
}

.sectionCinque {
  background-image: url('../../../public/imagesgaea21/EARTH3.jpg');
  background-size: cover;
  background-position: center;
  height: 400px;
}
.sectionSix {
  background-image: url('../../../public/imagesgaea21/EARTH2.jpg');
  background-size: cover;
  background-position: center;
  height: 400px;
}

.sectionHuit {
  background-image: url('../../../public/imagesgaea21/environmental.jpg');
  background-size: cover;
  background-position: center;
  height: 400px;
}

.section-theme
{
  background-image: url('../../../public/Image/background.jpg');
  background-size: cover;
  background-position: center;
  height: 500px;
}

i.material-icons{
  height: 3.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}
i>svg{
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}

#titreOffre{
  margin-bottom: 0;
  margin-top: 15px;
}


.col.partiDroite img{
  height: pxToVw(120px, 1920px);
margin-left: 70%;
}

div.categorieEmpreinte{
  background-color: #00000009;
  font-size: pxToVw(12px, 1920px);
  margin-bottom: pxToVw(50px, 1920px);
  top: 6382px;
  width: pxToVw(400px, 1920px);
  height: pxToVw(77px, 1920px);
  border-radius: 7px;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 5px 10px #0000002B;
  opacity: 1;
  img{
    height: pxToVw(80, 1920px);
    padding: 1vw;
    margin-right: pxToVw(25px, 1920px);
  }
}

div.col.partiGauche{
  border-right:1px solid #98984B;
  padding-top: 1vw;
  padding-bottom: 1vw;
}
div.col.partiDroite{
  padding-top: 1vw;
  padding-bottom: 1vw;
}
.buttonEmpreinte{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 2vw;
  margin-left: 12vw;
  margin-right: 12vw;
  border-radius: 1.7vw;
  padding-right: 140;
  box-shadow: 0 0.1vw 0.2vw 0 rgb(0,0,0,0.4);
  font-size: 0.9vw;
  transition: 0.2s;
  --local-background-color: white;
  color: #98984B;
  cursor: pointer;
}

.section.-complex>*>.buttonEmpreinte::after{
  content:"❯";
}
.no-hover-effect:hover {
  background-color: inherit;
}