@charset "UTF-8";
header {
  height: 6.2vw;
}

header > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 6.2vw;
  justify-content: space-between;
  box-shadow: 0 0 0.5em 0 rgb(0, 0, 0, 0.3);
  z-index: 10;
  position: relative;
  width: 100%;
}

header > div > * {
  margin-left: 1em;
  margin-right: 1em;
}

header > div > nav {
  background-color: transparent;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

header > div > nav > ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
}

header > div > nav > ul > li {
  flex-grow: 1;
}

header > div > nav > ul > li:hover {
  transform: scale(1.02, 1.02);
}

header > div > nav > ul > li > a:hover {
  background-color: transparent;
}

header > div > nav > ul > li > a {
  text-align: center;
  vertical-align: middle;
  font-size: 1.3vw;
  line-height: 1.3vw;
  font-weight: 500;
  color: black;
}

header > div > a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

header > div > a > img {
  height: 2.6vw;
  transition: transform 0.2s;
}

header > div > a > img:hover {
  transform: scale(1.1, 1.1);
}

/* Color */
.gaeaColorLight {
  background-color: #F5F5EE;
}

.gaeaColor {
  background-color: rgb(99, 119, 33);
}

.gaeaColorMiddle {
  background-color: #98984B;
  padding-top: 3%;
}

.gaeaGrey {
  background-color: #484738;
}

.gaeaCyan {
  background-color: #00A796;
}

.gaeaDarkCyan {
  background-color: #007263;
}

.textColor {
  color: rgb(99, 119, 33);
}

.backGreen {
  background-color: #C1C192;
}

.backBlue {
  background-color: #91C0B8;
}

.backPink {
  background-color: #B791C0;
}

.backRed {
  background-color: #FF5E61;
}

.backOrange {
  background-color: #D88F00;
}

.backVitre {
  background-color: rgb(255, 255, 255, 0.5);
}

/* */
/* Background Image */
.backImageSlide1 {
  background-image: url("../../../public/Image/slide/slide1.jpg");
  background-size: cover;
  background-position: center;
}

.backImageSlide2 {
  background-image: url("../../../public/Image/slide/slide2.jpg");
  background-size: cover;
  background-position: center;
}

.backImageSlide3 {
  background-image: url("../../../public/Image/slide/slide3.jpg");
  background-size: cover;
  background-position: center;
}

.backImageSlide4 {
  background-image: url("../../../public/Image/slide/slide4.jpg");
  background-size: cover;
  background-position: center;
}

.backImageSlide5 {
  background-image: url("../../../public/Image/slide/slide5.jpg");
  background-size: cover;
  background-position: center;
}

.backImageDiap1 {
  background-image: url("../../../public/Image/diap/diap1.png");
  background-size: cover;
  background-position: center;
}

.backImageDiap2 {
  background-image: url("../../../public/Image/diap/diap2.png");
  background-size: cover;
  background-position: center;
}

.backImageDiap3 {
  background-image: url("../../../public/Image/diap/diap3.png");
  background-size: cover;
  background-position: center;
}

.backImageDesert {
  background-image: url("../../../public/Image/desert.jpg");
  background-size: cover;
  background-position: center;
}

.backImageGreenhand {
  background-image: url("../../../public/Image/background.jpg");
  background-size: cover;
  background-position: center;
}

/* */
/* Background Gradient */
.backGreenBlueGradient {
  background: linear-gradient(90deg, #828858 0%, #839791 50%);
}

/* */
/* $swidthbase = width only */
/*pour faire le calcul pour transformer les px en %*/
/*pour faire le calcul pour transformer les px en %*/
/*pour faire le calcul pour transformer les px en vw*/
@media only screen and (min-width: 301px) {
  div.container {
    width: 70%;
  }
}
@media only screen and (min-width: 601px) {
  div.container {
    width: 70%;
  }
}
@media only screen and (min-width: 993px) {
  div.container {
    width: 70%;
  }
}
/* Global Style */
* {
  margin: 0;
  padding: 0;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

body {
  width: 100vw;
  font-family: "Roboto", sans-serif;
}
body li {
  list-style-type: none;
}

/* You can add global styles to this file, and also import other style files */
* {
  border-radius: 1px rgb(41, 46, 46);
  font-size: 1em;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

/* */
/* Scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(118, 126, 4) white;
}

/*  scrollbar  pour les autres navigateurs */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: rgb(35, 38, 41);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(111, 148, 10);
  border-radius: 20px;
  border: 1px solid rgb(36, 39, 39);
}

/* */
#section1 {
  height: 1.25vw;
}

#section2 {
  padding: 0;
  padding-left: 15%;
  padding-right: 15%;
}

#deco1 {
  height: 1.5vw;
  left: 1.3vw;
  top: 14.8vw;
  transform: translate(4vw, 0);
}

#section4 .statisticBackground {
  height: 15vw;
  width: 15vw;
  font-size: 1.25vw;
  background: url("../../../public/images/homepage/statistique.jpg");
}
#section4 .statisticBackground:hover span {
  color: white !important;
  background-size: cover;
  background-position: center;
}

#section4 .politicBackground {
  height: 15vw;
  width: 15vw;
  font-size: 1.25vw;
  background: url("../../../public/images/homepage/politique.jpg");
  background-size: cover;
  background-position: center;
}
#section4 .politicBackground:hover span {
  color: white !important;
  background-size: cover;
  background-position: center;
}

#section4 .actionProgramBackground {
  height: 15vw;
  width: 15vw;
  font-size: 1.25vw;
  background: url("../../../public/images/homepage/action-programme.jpg");
  background-size: cover;
  background-position: center;
}
#section4 .actionProgramBackground:hover span {
  color: white !important;
  background-size: cover;
  background-position: center;
}

#sectionNewSite {
  position: relative;
  font-family: "Roboto", sans-serif;
  color: black;
  text-wrap: wrap;
  font-size: 0.8333333333vw;
}
#sectionNewSite h3 {
  font-family: "Alfa Slab One";
  font-size: 1.4583333333vw;
}
#sectionNewSite button {
  position: absolute;
  bottom: -pxToVw(67px, 1920px);
  right: 1.0416666667vw;
  color: white;
  background-color: #007263;
  width: 7.8125vw;
  height: 2.0833333333vw;
  border: none;
  border-radius: 1.71875vw;
}
#sectionNewSite button p {
  margin: 0px;
}
#sectionNewSite button p:nth-of-type(1) {
  display: block;
}
#sectionNewSite button p:nth-of-type(2) {
  display: none;
}
#sectionNewSite button:hover {
  background-color: #40958A;
}
#sectionNewSite button:hover p:nth-of-type(1) {
  display: none;
}
#sectionNewSite button:hover p:nth-of-type(2) {
  display: block;
}

#sectionBlogNewletter {
  font-family: "Roboto";
  font-size: 0.8333333333vw;
  color: black;
  margin-bottom: 2vw;
}
#sectionBlogNewletter #sectionBlogNewletter1 {
  font-family: "Roboto", sans-serif;
}
#sectionBlogNewletter #sectionBlogNewletter1 h3 {
  font-weight: bold;
  font-family: "Alfa Slab One";
  color: #98984B;
  font-size: 1.4583333333vw;
}
#sectionBlogNewletter #sectionBlogNewletter1 p:nth-child(2) {
  font-size: 0.8333333333vw;
}
#sectionBlogNewletter #sectionBlogNewletter2 {
  margin-top: 5.2083333333vw;
}
#sectionBlogNewletter #sectionBlogNewletter2 #divOffres {
  display: flex;
  flex-direction: row;
}
#sectionBlogNewletter #sectionBlogNewletter2 #divOffres div {
  margin-top: 0.2604166667vw;
  display: flex;
  flex-direction: column;
}
#sectionBlogNewletter #sectionBlogNewletter2 #divOffres div div {
  flex-direction: row;
}
#sectionBlogNewletter #sectionBlogNewletter2 #divOffres div strong {
  font-weight: bold;
  line-height: 2vw;
}
#sectionBlogNewletter #sectionBlogNewletter2 img {
  width: 2.34375vw;
  height: 3.59375vw;
  margin-bottom: 1.1979166667vw;
  margin-right: 2.6041666667vw;
}
#sectionBlogNewletter #sectionBlogNewletter2 img:nth-of-type(1) {
  margin-top: 30px;
}
#sectionBlogNewletter #sectionBlogNewletter3 #divOffres {
  display: flex;
  flex-direction: row;
}
#sectionBlogNewletter #sectionBlogNewletter3 #divOffres div {
  display: flex;
  flex-direction: column;
}
#sectionBlogNewletter #sectionBlogNewletter3 #divOffres div div {
  flex-direction: row;
}
#sectionBlogNewletter #sectionBlogNewletter3 #divOffres div strong {
  font-weight: bold;
  line-height: 2vw;
}
#sectionBlogNewletter #sectionBlogNewletter3 img {
  width: 3.125vw;
  height: 3.0729166667vw;
  margin-bottom: 1.1979166667vw;
  margin-right: 2.6041666667vw;
}
#sectionBlogNewletter #sectionBlogNewletter3 img:nth-of-type(1) {
  margin-top: 0.3125vw;
}

/*.section {
  background-image: url('../../imagesgaea21/EARTH3.jpg');
  background-size: cover;
  background-position: center;
  min-height: 500px;
}*/
.sectionFirst {
  background-image: url("../../../public/imagesgaea21/field-176602_1920.jpg");
  background-size: cover;
  background-position: center;
  min-height: 500px;
}

.sectionCinque {
  background-image: url("../../../public/imagesgaea21/EARTH3.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
}

.sectionSix {
  background-image: url("../../../public/imagesgaea21/EARTH2.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
}

.sectionHuit {
  background-image: url("../../../public/imagesgaea21/environmental.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
}

.section-theme {
  background-image: url("../../../public/Image/background.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
}

i.material-icons {
  height: 3.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}

i > svg {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}

#titreOffre {
  margin-bottom: 0;
  margin-top: 15px;
}

.col.partiDroite img {
  height: 6.25vw;
  margin-left: 70%;
}

div.categorieEmpreinte {
  background-color: rgba(0, 0, 0, 0.0352941176);
  font-size: 0.625vw;
  margin-bottom: 2.6041666667vw;
  top: 6382px;
  width: 20.8333333333vw;
  height: 4.0104166667vw;
  border-radius: 7px;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.168627451);
  opacity: 1;
}
div.categorieEmpreinte img {
  height: 4.1666666667vw;
  padding: 1vw;
  margin-right: 1.3020833333vw;
}

div.col.partiGauche {
  border-right: 1px solid #98984B;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

div.col.partiDroite {
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.buttonEmpreinte {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 2vw;
  margin-left: 12vw;
  margin-right: 12vw;
  border-radius: 1.7vw;
  padding-right: 140;
  box-shadow: 0 0.1vw 0.2vw 0 rgba(0, 0, 0, 0.4);
  font-size: 0.9vw;
  transition: 0.2s;
  --local-background-color: white;
  color: #98984B;
  cursor: pointer;
}

.section.-complex > * > .buttonEmpreinte::after {
  content: "❯";
}

.no-hover-effect:hover {
  background-color: inherit;
}

/*
  gaeaSections.css
  Ce fichier déclare des sections.
  Une section occupe l'écran sur toutes sa largeur.
  @classes
    - Section(section)
    - Image Text Section(section-imagetext)
    - Card Section(section-cards-colored)
    - Text Section (section-text-colored)
*/
/* Sections */
/* Section ( section )
  Une section occupe l'écran sur toutes sa largeur.
  @abstract
  @modifiers
    _dark : si l'image est sombre
*/
.section {
  width: 100%;
  margin: 0;
}

/* */
/* Image Text Section ( section -imagetext )
  Une section composé de cours titres et de paragraphes sur un fond d'image.
  Le texte est disposé sur la partie gauche de l'image.
  @extend Section(section)
  @accept (br, p, h3)
  @modifiers
    _dark : si l'image est sombre
*/
.section.-imagetext {
  padding-top: 5em;
  padding-left: 9.5vw;
  padding-right: 8em;
}

.section.-imagetext {
  color: black;
}

.section.-imagetext._dark {
  color: white;
}

.section.-imagetext * {
  margin-top: 1.6vw;
}

.section.-imagetext p {
  font-size: 1.6vw;
  font-weight: 500;
  width: 47vw;
}

.section.-imagetext hr {
  width: 4.7vw;
  border: solid 0.2vw black;
  margin-left: 0;
}

.section.-imagetext._dark hr {
  border: solid 0.2vw white;
}

.section.-imagetext h3 {
  font-size: 4.4vw;
  font-weight: bold;
  width: 34.5vw;
}

/* */
/* Card Section ( section -cards )
  Une section composé des cartes.
  @extend Section(section)
  @accept (<div>(img, span, a, a.button)</div>)
  @modifiers
    _dark : si la couleur de fond est trop clair
    _thin : les cartes sont plus fines
    _topicon : l'image placé en premier enfant est un icone rond qui sort au dessus du cadre
    _square : Les cartes ont une forme de gros carré
    _sizeup : Les carte grandissent légérement lorsque la souris passe dessus
    _opacityup : L'opacité des carte augmente légérement lorsque la souris passe dessus
    _hidden : Les carte sont transaparentes tant que le pointeur ne passe pas dessus.
    _noshadow : Pas d'ombres sous les cartes.
    _cardlike : Forme similaire à une carte de jeu.
    _tight : Les cartes sont plus serrées
*/
.section.-cardsTrend {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding-bottom: 2vw;
}

.section.-cardsTrend._square > * {
  position: relative;
}

.section.-cardsTrend._square > * > span {
  color: rgb(0, 0, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 700;
}

.section.-cardsTrend > a {
  cursor: pointer;
  transition: transform 0.2s;
}

.section.-cards {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  flex-direction: row;
  padding: 0;
  padding-left: 8vw;
  padding-right: 8vw;
  padding-bottom: 5.4vw;
  --secondary-color: black;
  --secondary2-color: #CAC9BA;
  --text-color: rgb(255, 255, 255);
  color: var(--text-color);
  font-size: 0.9em;
}

.section.-cards._tight {
  padding-left: 18vw;
  padding-right: 18vw;
}

.section.-cards._dark {
  --secondary-color: white;
  --secondary2-color: #484738;
  --text-color: black;
}

.section.-cards > * {
  display: flex;
  flex-direction: column;
  width: 15.5vw;
  height: 13vw;
  margin: 0;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding-top: 1vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 1vw;
}

.section.-cards:not(._noshadow) > * {
  box-shadow: 0.5em 0.5em 0.8em 0em rgba(0, 0, 0, 0.2);
}

.section.-cards._thin > * {
  width: 13.4vw;
  height: 19vw;
}

.white.unvailable {
  opacity: 0.5;
  pointer-events: none;
}

.section.-cards._cardlike > * {
  width: 15vw;
  height: 19vw;
  padding-top: 3vw;
  border-radius: 1vw;
}

/* SQUARE */
.section.-cards._square > * {
  height: 25.4vw;
  width: 25.4vw;
  align-content: center;
  margin: 1vw;
  display: flex;
  justify-content: center;
}

.section.-cards._square > * > span {
  color: black;
  position: absolute;
  height: auto;
}

/* */
/* SIZEUP */
.section.-cards._sizeup > * {
  transition: transform 0.5s;
}

.section.-cards._sizeup > *:hover {
  transform: scale(1.02, 1.02);
}

/* */
/* OPACITYUP */
.section.-cards._opacityup > * {
  transition: filter 0.5s;
}

.section.-cards._opacityup > *:not(:hover) {
  filter: saturate(0.4) brightness(1.3) contrast(0.9);
}

/* */
/* HIDDEN */
.section.-cards._hidden > * {
  opacity: 0.6;
  transition: opacity 0.5s;
}

.section.-cards._hidden > *:hover {
  opacity: 1;
}

/* */
/* LINKED */
.section.-cards > a {
  cursor: pointer;
  transition: transform 0.2s;
}

/* */
/* IMAGE */
.section.-cards > * > img {
  height: 6vw;
  margin-bottom: 4vw;
}

/* */
/* TOPICON */
.section.-cards._topicon > * > img:first-child {
  position: absolute;
  top: -7vw;
  left: 3vw;
  height: 10vw;
  padding: 2.5vw;
}

.section.-cards._topicon > *::before {
  content: "";
  display: block;
  background-color: var(--text-color);
  border-radius: 6.5vw;
  box-shadow: 0 0 1vw 0vw rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -6.5vw;
  height: 9vw;
  width: 9vw;
}

.section.-cards._topicon > * {
  padding-top: 4vw;
  margin-top: 1vw;
  position: relative;
}

/* */
/* TEXT */
.section.-cards > * > span {
  text-align: center;
  height: 6vw;
  line-height: 1.8vw;
  font-size: 1.25vw;
  vertical-align: middle;
}

/* /*
/* LINK */
.section.-cards > * > a {
  font-weight: 500;
  cursor: pointer;
  height: 2vw;
  font-size: 1vw;
  color: white !important;
}

.section.-cards > * > a:hover {
  text-decoration: underline;
}

.section.-cards > * > a::after {
  content: " >";
  transform: scale(0.7, 1.3) translate(0, 0.025em);
  display: inline-block;
  margin-left: 0.5vw;
}

/* */
/* BUTTON */
.section.-cards > * > a.button {
  background-color: var(--secondary2-color);
  color: white !important;
  font-weight: 500;
  cursor: pointer;
  padding: 0.7em;
  padding-left: 1.6em;
  padding-right: 1.6em;
  width: auto;
  height: auto;
  font-size: 1vw;
  border-radius: 2em;
  transition: 0.5s;
}

.section.-cards > * > a.button:hover {
  text-decoration: none;
  transform: scale(1.1, 1.1);
  box-shadow: 0 0 0.5em 0.1em rgba(0, 0, 0, 0.5);
}

/* */
/* */
h3.center.titreGreen {
  font-size: 1.875vw !important;
}

/* Text Section ( section -text )
  Une section composé de titres et de paragraphes centrés.
  @extend Section(section)
  @accept (h2,h3,h4,p)
  @modifiers
    _dark : si la couleur de fond est trop clair
*/
.section.-text {
  display: flex;
  flex-direction: column;
  color: black;
  padding-left: 6.5vw;
  padding-right: 6.5vw;
  /* TITLE */
  /* */
  /* LINK */
  /* */
  /* PARAGRAPH */
  /* */
}
.section.-text._dark {
  color: white;
}
.section.-text p {
  font-size: 1.25vw;
}
.section.-text > h2 {
  font-size: 3.5vw;
  width: 54.3;
  font-weight: bolder;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.section.-text > h3 {
  font-size: 1.25vw;
  font-family: "Alfa Slab One", Regular;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 4em;
  padding-right: 4em;
}
.section.-text > h4 {
  font-size: 1.25vw;
  font-family: "Alfa Slab One", sans-serif;
  font-weight: bolder;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 5.8em;
  padding-right: 5.8em;
}
.section.-text > h5 {
  font-size: 1.25vw;
  font-family: "Alfa Slab One", Regular;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 5.8em;
  padding-right: 5.8em;
}
.section.-text > a {
  font-size: 1.2vw;
  align-self: flex-end;
  text-decoration: underline;
}
.section.-text > a + a {
  margin-top: -2vw;
  align-self: flex-start;
}
.section.-text > p {
  font-size: 1.25vw;
  font-family: "Roboto", Bold;
  text-align: center;
}

/* */
div.textGaea {
  font-size: 0.8333333333vw;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1vw;
}

/* Event List Section ( section -event )
  Une section composé d'une liste d'événements
  @extend Section(section)
  @accept (<div>time, span._methode, span._date, span._author, span._titre, a._inscription, a._plus</div>)
*/
.section.-event {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 9.5vw;
  padding-right: 9.5vw;
}

.section.-event > * {
  position: relative;
  padding: 1.2vw;
  margin-bottom: 6vw;
  width: 23.8vw;
  height: 16vw;
  border-radius: 0.3vw;
  box-shadow: 0 0 0.4vw 0.1vw rgba(0, 0, 0, 0.1);
}

/* Date */
.section.-event > * > time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5.65vw;
  height: 5.29vw;
  border-radius: 0.3vw;
  padding: 0.5vw;
  box-shadow: 0 0 0.4vw 0.1vw rgba(0, 0, 0, 0.1);
}

.section.-event > * > time > span:first-child {
  font-size: 1.5vw;
  height: 1.8vw;
}

.section.-event > * > time > span:last-child {
  font-size: 2.3vw;
}

/* */
/* Methode */
.section.-event > * > ._methode {
  display: block;
  text-align: center;
  width: 5.65vw;
  height: 1.7vw;
  border-radius: 0.3vw;
  position: absolute;
  top: 7.2vw;
  left: 1.3vw;
  font-size: 1.2vw;
  color: white;
  word-break: break-all;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  cursor: help;
}

.section.-event > * > ._methode:hover {
  width: auto;
  min-width: 5.65vw;
  z-index: 10;
}

/* */
/* Date Name */
.section.-event > * > ._date {
  display: block;
  width: 15.4vw;
  position: absolute;
  top: 1.5vw;
  left: 8vw;
  font-size: 1.5vw;
  color: black;
}

/* */
/* Author Name */
.section.-event > * > ._author {
  display: block;
  width: 15.4vw;
  position: absolute;
  top: 4vw;
  left: 8vw;
  font-size: 0.9vw;
  font-style: italic;
  color: #8E8E8E;
}

/* */
/* Title Name */
.section.-event > * > ._titre {
  display: block;
  width: 14.5vw;
  height: 4vw;
  position: absolute;
  top: 7vw;
  left: 8vw;
  font-size: 1.2vw;
  color: #8E8E8E;
}

/* */
/* Link */
.section.-event > * > ._inscription {
  display: block;
  width: 14.5vw;
  height: 1.5vw;
  position: absolute;
  top: 11.4vw;
  left: 7.2vw;
  font-size: 1.25vw;
  color: #9B9B50;
  cursor: pointer;
}

.section.-event > * > ._inscription:hover {
  text-decoration: underline;
}

.section.-event > * > ._inscription:after {
  content: "→";
  padding-left: 4.2vw;
}

/* */
/* Button */
.section.-event > * > a._plus {
  display: flex;
  width: 16vw;
  height: 3.4vw;
  position: absolute;
  top: 18vw;
  left: 4vw;
  font-size: 1.2vw;
  align-items: center;
  justify-content: center;
  border-radius: 1.7vw;
  background-color: #98984B;
  color: white;
  cursor: pointer;
  transition: 0.2s;
}

.section.-event > * > a._plus:hover {
  background-color: #fafafa;
  color: #98984B;
}

.section.-event > * > ._plus::after {
  content: "❯";
  padding-left: 0.5vw;
  transform: scale(1.5, 1.5) translate(0, 0.06vw);
}

/* */
/* */
/* */
/* Button Bar ( section -buttonbar )
  Une section composé d'un titre et de boutons
  @extend Section(section)
  @accept (h4),(h5),(a)
*/
.section.-buttonbar {
  display: flex;
  flex-direction: row;
  height: 7.4vw;
  padding-top: 1.7vw;
  padding-left: 9.5vw;
  padding-right: 9.5vw;
  align-items: center;
}

.section.-buttonbar > * {
  margin: 0;
  padding: 0;
}

/* TITLE */
.section.-buttonbar > h3 {
  display: flex;
  text-align: center;
  font-size: 1.25vw;
  font-weight: bold;
  color: #98984B;
  margin-right: 2.1vw;
}

.section.-buttonbar > h4 {
  display: flex;
  font-family: "Roboto";
  font-size: 1.25vw;
  margin-right: 2.1vw;
}

.section.-buttonbar > h5 {
  display: flex;
  font-size: 1.5vw;
  margin-right: 2.1vw;
}

/* */
/* BUTTON */
.section.-buttonbar > a:first-of-type {
  margin-left: auto;
}

.section.-buttonbar > a {
  display: flex;
  height: 4vw;
  width: 4vw;
  border-radius: 2.5vw;
  justify-content: center;
  align-items: center;
  margin-left: 1vw;
  margin-right: 1vw;
  color: #98984B;
  box-shadow: 0 0.2vw 0.3vw 0.1vw rgba(0, 0, 0, 0.1);
  font-size: 2vw;
  cursor: pointer;
  transition: 0.5s;
}

.section.-buttonbar > a:hover {
  box-shadow: 0 0 0.1vw 0 rgba(0, 0, 0, 0.1);
}

/* */
/* */
/* Block Select ( section -blockselect )
  Une zone de selection de bloques
  @extend Section(section)
  @accept (h4),(h5),(a)
*/
.section.-blockselect {
  display: flex;
  flex-direction: row;
  padding-left: 9.5vw;
  padding-right: 9.5vw;
  justify-content: space-between;
}

/* Menu */
.section.-blockselect > ._menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section.-blockselect > ._menu > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-left: 14.7vw;
  height: 9.5vw;
  transition: 0.5s;
}

.section.-blockselect > ._menu > * > div {
  width: 11.7vw;
  height: 100%;
  border-radius: 1vw;
  position: absolute;
  left: 0;
  top: 0;
}

.section.-blockselect > ._menu > * > h4 {
  font-size: 1.3vw;
  font-weight: bold;
  margin: 0;
}

.section.-blockselect > ._menu > * > p {
  font-size: 1vw;
  margin: 0;
  width: 14vw;
}

/* */
/* Screen */
.section.-blockselect > ._screen {
  height: 31.9vw;
  width: 39vw;
}

.section.-blockselect > ._screen > * {
  height: 100%;
  width: 100%;
  border-radius: 1vw;
}

/* */
/* */
/* Complex Section ( section -complex )
  Une avec du texte et des tableaux complexs
  @extend Section(section)
  @accept (<div>(table,a,em,p,span.number)<div>)
*/
.section.-complex {
  display: flex;
  flex-direction: row;
  padding-left: 9.5vw;
  padding-right: 9.5vw;
  justify-content: space-between;
}

.section.-complex > * {
  flex-direction: column;
  justify-content: center;
}

/* LINKS */
.section.-complex > * > a {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 2vw;
  width: 10.3vw;
  border-radius: 1.7vw;
  box-shadow: 0 0.1vw 0.2vw 0 rgba(0, 0, 0, 0.4);
  margin-top: 1vw;
  margin-bottom: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  font-size: 0.9vw;
  transition: 0.2s;
  --local-background-color: white;
  --local-color: #98984B;
  background-color: var(--local-background-color);
  color: var(--local-color);
  cursor: pointer;
}

.section.-complex > * > a:hover {
  box-shadow: 0 0vw 0.1vw 0 rgba(0, 0, 0, 0.2);
}

.section.-complex > * > a::after {
  content: "❯";
}

.section.-complex > * > a:nth-child(2n) {
  --local-background-color: #ffffff;
  margin-left: 24px;
  color: #98984B !important;
  --local-color: #98984B;
}

/* */
/* TEXT */
.section.-complex > * span.number {
  font-size: 1.5em;
  color: #98984B;
}

.section.-complex > * em {
  font-size: 1.5em;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
}

/* */
/* TEXT DEHORS */
.section.-complex > * > p {
  font-size: 1.0416666667vw;
  padding-left: 2vw;
  padding-right: 3vw;
}

/* */
/* TABLE */
.section.-complex > * > table {
  display: flex;
  flex-direction: column;
}

.section.-complex > * > table > * > tr {
  display: flex;
  flex-direction: row;
  border: none;
}

.section.-complex > * > table > thead {
  margin-bottom: 3.7vw;
}

.section.-complex > * > table > * > tr > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 1vw;
  line-height: 2em;
}

.section.-complex > * > table > * > tr > * > img {
  height: 4.8vw;
}

.section.-complex > * > table > * > tr > * > * {
  text-align: center;
  margin: 0;
  margin-bottom: 0.2vw;
}

.section.-complex > * > table > * > tr > * > p {
  line-height: 1.1em;
}

.section.-complex > * > table > thead > tr > th {
  background-color: #F1F1E7;
  border-radius: 2.5vw;
  height: 7.9vw;
  box-shadow: 0 0.6vw 0.8vw 0 rgba(0, 0, 0, 0.2);
}

.section.-complex > * > table > tbody > tr > td {
  width: 17.1vw;
  padding: 0;
  padding-left: 4vw;
  padding-right: 4vw;
}

.section.-complex > * > table > tbody > tr > td {
  position: relative;
}

.section.-complex > * > table > tbody > tr > td:not(:last-child)::after {
  content: "";
  display: block;
  border-right: #98984B solid 1px;
  position: absolute;
  top: 0;
  left: 0;
  width: 17.1vw;
  height: 6.1vw;
}

.section.-complex > * > table > thead > tr > th {
  background-color: #F1F1E7;
  border-radius: 2.5vw;
  height: 7.9vw;
  box-shadow: 0 0.6vw 0.8vw 0 rgba(0, 0, 0, 0.2);
  width: 15.1vw;
  margin: 1vw;
}

/* */
/* */
/* */
/* OBJECTS */
/* Image Text Object ( object -imagetext )
  Une zone de texte faite pour être posée sur un font d'image.
  @extend Section(section)
  @accept (h4),(h5),(a)
*/
.object.-imagetext {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 4.1vw;
  padding-bottom: 3vw;
  color: white;
}

.object.-imagetext > * {
  margin: 0;
}

.object.-imagetext > h4 {
  font-size: 1.5vw;
  font-weight: bold;
}

.object.-imagetext > p {
  font-size: 1.2vw;
  width: 24.2vw;
  padding-bottom: 0.5vw;
}

.object.-imagetext > a {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  background-color: white;
  color: #98984B;
  border-radius: 1.7vw;
  height: 3.4vw;
  width: 3.4vw;
  padding: 0.5vw;
  font-size: 1.2vw;
  transition: 0.5s;
  cursor: pointer;
}

.object.-imagetext > a:hover {
  width: 12.6vw;
}

.object.-imagetext > a::before {
  content: "❯";
  padding-left: 0.85vw;
  padding-right: 2vw;
  font-size: 1.6vw;
  transition: 0.5s;
}

.object.-imagetext > a:hover::before {
  padding-right: 0.9vw;
}

/* */
/* Right Side ( object -rightside )
  Se pose sur la droite de l'écran
  @extend Section(section)
*/
.object.-rightside {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
  height: 0;
  width: 100%;
  position: relative;
}

.object.-rightside > * {
  position: relative;
  bottom: -14vw;
  left: -15vw;
  right: 0;
  transition: 1s;
}

.object.-rightside > *:not(:hover) {
  transition: 1s;
}

/* */
/* Small Text Object ( object -smalltext )
  Une zone de texte faite pour être posée sur un font d'image.
  @extend Section(section)
  @accept (h4),(h5),(a)
*/
.object.-smalltext {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
}

.object.-smalltext > img {
  display: block;
  height: 6vw;
}

.object.-smalltext > p {
  height: 12vw;
  font-size: 1vw;
  font-style: italic;
  text-align: center;
  width: 15vw;
}

.object.-smalltext > span {
  color: #E0DDC9;
  font-weight: bold;
}

.object.-smalltext > span.name {
  font-size: 1.2vw;
}

.object.-smalltext > span.role {
  font-size: 1vw;
  text-decoration: underline;
}

/* */
/* */
/* MOVING */
/* Carrousel ( moving -rotating -largecarrousel )
  @extend Section(section)
  @accept (br, p, h3)
*/
.moving.-rotating.-largecarrousel {
  position: relative;
  padding: 0;
}

.moving.-rotating.-largecarrousel > * {
  transition: 1s;
  opacity: 0;
  height: 100%;
}

.moving.-rotating.-largecarrousel > *:nth-child(1) {
  position: absolute;
  top: 0;
  right: 100%;
}

.moving.-rotating.-largecarrousel > *:nth-child(2) {
  position: relative;
  top: 0;
  right: 0%;
  opacity: 1;
}

.moving.-rotating.-largecarrousel > *:nth-child(3) {
  position: absolute;
  top: 0;
  right: -100%;
}

/* */
/* Carrousel ( moving -rotating -lateralcarrousel )
  @extend Section(section)
  @accept (br, p, h3)
*/
.moving.-rotating.-lateralcarrousel {
  position: relative;
  padding: 0;
}

.moving.-rotating.-lateralcarrousel > * {
  position: absolute;
  transition: 1s;
  height: 100%;
  width: 25%;
}

.moving.-rotating.-lateralcarrousel > *:nth-child(1) {
  position: relative;
  top: 0;
  left: -45%;
}

.moving.-rotating.-lateralcarrousel > *:nth-child(2) {
  position: absolute;
  top: 0;
  left: 12.5%;
}

.moving.-rotating.-lateralcarrousel > *:nth-child(3) {
  position: absolute;
  top: 0;
  left: 37.5%;
}

.moving.-rotating.-lateralcarrousel > *:nth-child(4) {
  position: absolute;
  top: 0;
  left: 62.5%;
}

.moving.-rotating.-lateralcarrousel > *:nth-child(5) {
  position: absolute;
  top: 0;
  left: 120%;
}

/* */
/* Small Diaporama ( moving -rotating -smalldiaporama )
  Un cadre de diaporama.
  @extend Section(section)
  @accept (br, p, h3)
*/
.moving.-rotating.-smalldiaporama {
  position: relative;
}

.moving.-rotating.-smalldiaporama > *:not(:nth-child(1)) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation-direction: reverse;
  display: none;
}

.moving.-rotating.-smalldiaporama > *:nth-child(1) {
  animation-direction: normal;
}

.moving.-rotating.-smalldiaporama > * {
  z-index: 100;
  animation-name: moving-rotation-smalldiaporama-animation;
  animation-duration: 0.3s;
}

@keyframes moving-rotation-smalldiaporama-animation {
  from {
    display: none;
    opacity: 0;
  }
  1% {
    display: inherit;
    opacity: 0;
  }
  to {
    display: inherit;
    opacity: 1;
  }
}
/* */
/* */
/* CONTROLLERS */
/* Radio Block ( controls -radio -verticalblock )
  Une zone de selection de blocs.
  @extend Section(section)
  @accept (h4),(h5),(a)
*/
.controls.-radio.-verticalblock > * {
  transition: 0.5s;
  cursor: pointer;
}

.controls.-radio.-verticalblock > *:not(._selected) {
  opacity: 0.5;
}

.controls.-radio.-verticalblock > *:hover {
  transform: scale(1.02, 1.02);
}

/* */
/* Radio Block ( controls -radio -horizontaldot )
  Une zone de selection de blocs.
  @extend Section(section)
  @accept (button)
*/
.controls.-radio.-horizontaldot {
  display: flex;
  flex-direction: row;
  padding-top: 1.5vw;
  padding-bottom: 3vw;
  margin: 0;
  justify-content: center;
}

.controls.-radio.-horizontaldot > * {
  border: none;
  outline: none;
  background: none;
  background-color: rgb(180, 180, 180);
  margin: 0.35vw;
  width: 0.7vw;
  height: 0.7vw;
  border-radius: 1vw;
  cursor: pointer;
}

.controls.-radio.-horizontaldot > *:hover {
  transform: scale(1.2, 1.2);
}

.controls.-radio.-horizontaldot > *._selected {
  background-color: white;
}

/* */
/* */