header{
  height: 6.2vw;
}
header>div{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 6.2vw;
  justify-content: space-between;
  box-shadow: 0 0 0.5em 0 rgb(0,0,0,0.3);
  z-index: 10;
  position: relative;
  width: 100%;
}
header>div>*{
  margin-left: 1em;
  margin-right: 1em;
}
header>div>nav{
  background-color: transparent;
  box-shadow: none;
  margin: 0;
  padding: 0;
}
header>div>nav>ul{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
}
header>div>nav>ul>li{
  flex-grow: 1;
}
header>div>nav>ul>li:hover{
  transform: scale(1.02,1.02);
}
header>div>nav>ul>li>a:hover{
  background-color: transparent;
}
header>div>nav>ul>li>a{
  text-align: center;
  vertical-align: middle;
  font-size: 1.3vw;
  line-height: 1.3vw;
  font-weight: 500;
  color: black;
}
header>div>a{
  display: flex;
  flex-direction: row;
  align-items: center;
}
header>div>a>img{
  height:2.6vw;

  transition: transform 0.2s;
}
header>div>a>img:hover{
  transform: scale(1.1,1.1);
}
