/*
  gaeaSections.css
  Ce fichier déclare des sections.
  Une section occupe l'écran sur toutes sa largeur.
  @classes
    - Section(section)
    - Image Text Section(section-imagetext)
    - Card Section(section-cards-colored)
    - Text Section (section-text-colored)
*/
/* Sections */



  /* Section ( section )
    Une section occupe l'écran sur toutes sa largeur.
    @abstract
    @modifiers
      _dark : si l'image est sombre
  */
  .section{
    width: 100%;
    margin: 0;
  }
/* */



/* Image Text Section ( section -imagetext )
  Une section composé de cours titres et de paragraphes sur un fond d'image.
  Le texte est disposé sur la partie gauche de l'image.
  @extend Section(section)
  @accept (br, p, h3)
  @modifiers
    _dark : si l'image est sombre
*/
  .section.-imagetext{
    padding-top: 5em;
    padding-left: 9.5vw;
    padding-right: 8em;
  }
  .section.-imagetext{
    color: black;
  }
  .section.-imagetext._dark{
    color: white;
  }
  .section.-imagetext *{
    margin-top: 1.6vw;
  }
  .section.-imagetext p{
    font-size: 1.6vw;
    font-weight: 500;
    width: 47vw;
  }
  .section.-imagetext hr{
    width:4.7vw;
    border: solid 0.2vw black;
    margin-left: 0;
  }
  .section.-imagetext._dark hr{
    border: solid 0.2vw white;
  }
  .section.-imagetext h3{
    font-size: 4.4vw;
    font-weight: bold;
    width: 34.5vw;
  }
/* */







/* Card Section ( section -cards )
  Une section composé des cartes.
  @extend Section(section)
  @accept (<div>(img, span, a, a.button)</div>)
  @modifiers
    _dark : si la couleur de fond est trop clair
    _thin : les cartes sont plus fines
    _topicon : l'image placé en premier enfant est un icone rond qui sort au dessus du cadre
    _square : Les cartes ont une forme de gros carré
    _sizeup : Les carte grandissent légérement lorsque la souris passe dessus
    _opacityup : L'opacité des carte augmente légérement lorsque la souris passe dessus
    _hidden : Les carte sont transaparentes tant que le pointeur ne passe pas dessus.
    _noshadow : Pas d'ombres sous les cartes.
    _cardlike : Forme similaire à une carte de jeu.
    _tight : Les cartes sont plus serrées
*/



.section.-cardsTrend{
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding-bottom: 2vw;
}

.section.-cardsTrend._square>*{
  position: relative;
}

.section.-cardsTrend._square>*>span{
  color: rgb(0, 0, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 700;
}
.section.-cardsTrend>a{
  cursor: pointer;
  transition: transform 0.2s;
}



  .section.-cards{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    flex-direction: row;
    padding: 0;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-bottom: 5.4vw;
    --secondary-color: black;
    --secondary2-color: #CAC9BA;
    --text-color: rgb(255, 255, 255);
    color: var(--text-color);
    font-size: 0.9em;
  }
  .section.-cards._tight{
    padding-left: 18vw;
    padding-right: 18vw;
  }
  .section.-cards._dark{
    --secondary-color: white;
    --secondary2-color: #484738;
    --text-color: black;
  }
  .section.-cards>*{
    display: flex;
    flex-direction: column;
    width: 15.5vw;
    height: 13vw;
    margin: 0;
    align-items: center;
    position: relative;
    justify-content: space-between;
    padding-top: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 1vw;
  }
  .section.-cards:not(._noshadow)>*{
    box-shadow: 0.5em 0.5em 0.8em 0em rgba(0,0,0,0.2);
  }
  .section.-cards._thin>*{
    width: 13.4vw;
    height: 19vw;
  }
  .white.unvailable {
    opacity: 0.5;
    pointer-events: none;
  }    
  .section.-cards._cardlike>*{
    width: 15vw;
    height: 19vw;
    padding-top: 3vw;
    border-radius: 1vw;
  }
  /* SQUARE */
    .section.-cards._square>*{
      height: 25.4vw;
      width: 25.4vw;
      align-content: center;
      margin: 1vw;
      display:flex;
      justify-content: center;
    }
    .section.-cards._square>*>span{
      color: black;
      position: absolute;
      height: auto;
    }
  /* */
  /* SIZEUP */
    .section.-cards._sizeup>*{
      transition: transform 0.5s;
    }
    .section.-cards._sizeup>*:hover{
      transform: scale(1.02,1.02);
    }
  /* */
  /* OPACITYUP */
    .section.-cards._opacityup>*{
      transition: filter 0.5s;
    }
    .section.-cards._opacityup>*:not(:hover){
      filter: saturate(0.4) brightness(1.3) contrast(0.9);
    }
  /* */
  /* HIDDEN */
    .section.-cards._hidden>*{
      opacity: 0.6;
      transition: opacity 0.5s;
    }
    .section.-cards._hidden>*:hover{
      opacity: 1;
    }
  /* */
  /* LINKED */
    .section.-cards>a{
      cursor: pointer;
      transition: transform 0.2s;
    }
    // .section.-cards>a:hover{
    //   transform: scale(1.1,1.1);
    // }
  /* */
  /* IMAGE */
    .section.-cards>*>img{
      height: 6vw;
      margin-bottom: 4vw;
    }
  /* */
  /* TOPICON */
    .section.-cards._topicon>*>img:first-child{
      position: absolute;
      top:-7.0vw;
      left: 3vw;
      height: 10vw;
      padding: 2.5vw;
    }
    .section.-cards._topicon>*::before{
      content: "";
      display: block;
      background-color: var(--text-color);
      border-radius: 6.5vw;
      box-shadow: 0 0 1vw 0vw rgba(0,0,0,0.2);
      position: absolute;
      top:-6.5vw;
      height: 9vw;
      width: 9vw;
    }
    .section.-cards._topicon>*{
      padding-top: 4vw;
      margin-top: 1vw;
      position: relative;
    }
  /* */
  /* TEXT */
    .section.-cards>*>span{
      text-align: center;
      height: 6vw;
      line-height: 1.8vw;
      font-size: pxToVw(24px, 1920px);
      vertical-align: middle;
    }
  /* /*
  /* LINK */
    .section.-cards>*>a{
      font-weight: 500;
      cursor: pointer;
      height: 2vw;
      font-size: 1vw;
      color:white !important;
    }
    .section.-cards>*>a:hover{
      text-decoration: underline;
    }
    .section.-cards>*>a::after{
      content:" >";
      transform: scale(0.7,1.3) translate(0,0.025em);
      display: inline-block;
      margin-left: 0.5vw;
    }
  /* */
  /* BUTTON */
    .section.-cards>*>a.button{
      background-color: var(--secondary2-color);
      color: white !important;
      font-weight: 500;
      cursor: pointer;
      padding: 0.7em;
      padding-left: 1.6em;
      padding-right: 1.6em;
      width: auto;
      height: auto;
      font-size: 1vw;
      border-radius: 2em;
      transition: 0.5s;
    }
    .section.-cards>*>a.button:hover{
      text-decoration: none;
      transform: scale(1.1,1.1);
      box-shadow: 0 0 0.5em 0.1em rgba(0,0,0,0.5);
    }
  /* */
/* */

h3.center.titreGreen{
  font-size:pxToVw(36px, 1920px) !important;
}

/* Text Section ( section -text )
  Une section composé de titres et de paragraphes centrés.
  @extend Section(section)
  @accept (h2,h3,h4,p)
  @modifiers
    _dark : si la couleur de fond est trop clair
*/
.section.-text{
  display: flex;
  flex-direction: column;
  color: black;
  padding-left: 6.5vw;
  padding-right: 6.5vw;
  &._dark{
    color: white;
  }
  p{
    font-size:pxToVw(24px, 1920px) ;
  }
  /* TITLE */
    &>h2{
      font-size: 3.5vw;
      width: 54.3;
      font-weight: bolder;
      margin-top: 2vw;
      margin-bottom: 2vw;
    }
    &>h3{
      font-size:  pxToVw(24px, 1920px);
      font-family: "Alfa Slab One", Regular;
      margin-top: 1em;
      margin-bottom: 1em;
      padding-left: 4em;
      padding-right: 4em;
    }
    &>h4{
      font-size:  pxToVw(24px, 1920px);
      font-family: "Alfa Slab One", sans-serif;
      font-weight: bolder;
      margin-top: 1em;
      margin-bottom: 1em;
      padding-left: 5.8em;
      padding-right: 5.8em;
    }
    &>h5{
      font-size:  pxToVw(24px, 1920px);
      font-family: "Alfa Slab One", Regular;
      margin-top: 1em;
      margin-bottom: 1em;
      padding-left: 5.8em;
      padding-right: 5.8em;
    }
  /* */
  /* LINK */
    &>a{
      font-size: 1.2vw;
      align-self: flex-end;
      text-decoration: underline;
      &+a{
        margin-top: -2vw;
        align-self: flex-start;
      }
    }
  /* */
  /* PARAGRAPH */
    &>p{
      font-size: pxToVw(24px, 1920px);
      font-family: "Roboto",Bold;
      text-align: center;
    }
  /* */
}
/* */
div.textGaea{
  font-size: pxToVw(16px, 1920px);
  margin-left:10% ;
  margin-right:10% ;
  margin-top: 1vw;
}
/* Event List Section ( section -event )
  Une section composé d'une liste d'événements
  @extend Section(section)
  @accept (<div>time, span._methode, span._date, span._author, span._titre, a._inscription, a._plus</div>)
*/
  .section.-event{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-left: 9.5vw;
    padding-right: 9.5vw;
  }
  .section.-event>*{
    position: relative;
    padding: 1.2vw;
    margin-bottom: 6vw;
    width: 23.8vw;
    height: 16vw;
    border-radius: 0.3vw;

    box-shadow: 0 0 0.4vw 0.1vw rgb(0,0,0,0.1);
  }
    /* Date */
    .section.-event>*>time{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 5.65vw;
      height: 5.29vw;
      border-radius: 0.3vw;
      padding: 0.5vw;

      box-shadow: 0 0 0.4vw 0.1vw rgb(0,0,0,0.1);
    }
    .section.-event>*>time>span:first-child{
      font-size: 1.5vw;
      height: 1.8vw;
    }
    .section.-event>*>time>span:last-child{
      font-size: 2.3vw;
    }
    /* */
    /* Methode */
    .section.-event>*>._methode{
      display: block;
      text-align: center;
      width: 5.65vw;
      height: 1.7vw;
      border-radius: 0.3vw;
      position: absolute;
      top: 7.2vw;
      left: 1.3vw;
      font-size: 1.2vw;
      color: white;
      word-break: break-all;
      padding-left: 0.5vw;
      padding-right: 0.5vw;
      cursor: help;
    }
    .section.-event>*>._methode:hover{
      width: auto;
      min-width: 5.65vw;
      z-index: 10;
    }
    /* */
    /* Date Name */
    .section.-event>*>._date{
      display: block;
      width: 15.4vw;
      position: absolute;
      top: 1.5vw;
      left: 8vw;
      font-size: 1.5vw;
      color: black;
    }
    /* */
    /* Author Name */
    .section.-event>*>._author{
      display: block;
      width: 15.4vw;
      position: absolute;
      top: 4vw;
      left: 8vw;
      font-size: 0.9vw;
      font-style: italic;
      color: #8E8E8E;
    }
    /* */
    /* Title Name */
    .section.-event>*>._titre{
      display: block;
      width: 14.5vw;
      height: 4vw;
      position: absolute;
      top: 7vw;
      left: 8vw;
      font-size: 1.2vw;
      color: #8E8E8E;
    }
    /* */
    /* Link */
    .section.-event>*>._inscription{
      display: block;
      width: 14.5vw;
      height: 1.5vw;
      position: absolute;
      top: 11.4vw;
      left: 7.2vw;
      font-size: 1.25vw;
      color: #9B9B50;
      cursor: pointer;
    }
    .section.-event>*>._inscription:hover{
      text-decoration: underline;
    }
    .section.-event>*>._inscription:after{
      content:"→";
      padding-left: 4.2vw;
    }
    /* */
    /* Button */
    .section.-event>*>a._plus{
      display: flex;
      width: 16vw;
      height: 3.4vw;
      position: absolute;
      top: 18vw;
      left: 4vw;
      font-size: 1.2vw;
      align-items: center;
      justify-content: center;
      border-radius: 1.7vw;

      background-color: #98984B;
      color: white;

      cursor: pointer;

      transition: 0.2s;
    }
    .section.-event>*>a._plus:hover{
      background-color: #fafafa;
      color: #98984B;
    }
    .section.-event>*>._plus::after{
      content: "❯";
      padding-left: 0.5vw;
      transform: scale(1.5,1.5) translate(0,0.06vw);
    }
    /* */
  /* */
/* */

/* Button Bar ( section -buttonbar )
  Une section composé d'un titre et de boutons
  @extend Section(section)
  @accept (h4),(h5),(a)
*/
.section.-buttonbar{
  display: flex;
  flex-direction: row;
  height: 7.4vw;
  padding-top: 1.7vw;
  padding-left: 9.5vw;
  padding-right: 9.5vw;
  align-items: center;
}
.section.-buttonbar>*{
  margin: 0;
  padding: 0;
}
  /* TITLE */
  .section.-buttonbar>h3{
    display: flex;
    text-align: center;
    font-size: pxToVw(24px, 1920px);
    font-weight: bold;
    color: #98984B;
    margin-right: 2.1vw;
  }
  .section.-buttonbar>h4{
    display: flex;
    font-family: "Roboto";
    font-size: pxToVw(24px, 1920px);
    margin-right: 2.1vw;
  }
  .section.-buttonbar>h5{
    display: flex;
    font-size: 1.5vw;
    margin-right: 2.1vw;
  }
  /* */
  /* BUTTON */
  .section.-buttonbar>a:first-of-type{
    margin-left: auto;
  }
  .section.-buttonbar>a{
    display: flex;
    height: 4vw;
    width: 4vw;
    border-radius: 2.5vw;
    justify-content: center;
    align-items: center;
    margin-left: 1vw;
    margin-right: 1vw;
    color: #98984B;
    box-shadow: 0 0.2vw 0.3vw 0.1vw rgb(0,0,0,0.1);
    font-size: 2vw;
    cursor: pointer;
    transition: 0.5s;
  }
  .section.-buttonbar>a:hover{
    box-shadow: 0 0 0.1vw 0 rgb(0,0,0,0.1);
  }
  /* */
/* */

/* Block Select ( section -blockselect )
  Une zone de selection de bloques
  @extend Section(section)
  @accept (h4),(h5),(a)
*/
  .section.-blockselect{
    display: flex;
    flex-direction: row;

    padding-left: 9.5vw;
    padding-right: 9.5vw;

    justify-content: space-between;
  }
  /* Menu */
    .section.-blockselect>._menu{
      display: flex;
      flex-direction: column;

      justify-content: space-between;
    }
    .section.-blockselect>._menu>*{
      display: flex;
      flex-direction: column;
      justify-content: center;

      position: relative;

      padding-left: 14.7vw;
      height: 9.5vw;

      transition: 0.5s;
    }
    .section.-blockselect>._menu>*>div{
      width: 11.7vw;
      height: 100%;
      border-radius: 1vw;

      position: absolute;
      left: 0;
      top: 0;
    }
    .section.-blockselect>._menu>*>h4{
      font-size: 1.3vw;
      font-weight: bold;
      margin: 0;
    }
    .section.-blockselect>._menu>*>p{
      font-size: 1vw;
      margin: 0;
      width: 14vw;
    }
  /* */
  /* Screen */
    .section.-blockselect>._screen{
      height: 31.9vw;
      width: 39.0vw;
    }
    .section.-blockselect>._screen>*{
      height: 100%;
      width: 100%;
      border-radius: 1vw;
    }
  /* */
/* */

/* Complex Section ( section -complex )
  Une avec du texte et des tableaux complexs
  @extend Section(section)
  @accept (<div>(table,a,em,p,span.number)<div>)
*/
  .section.-complex{
    display: flex;
    flex-direction: row;
    padding-left: 9.5vw;
    padding-right: 9.5vw;
    justify-content: space-between;
  }
  .section.-complex>*{
    flex-direction: column;
    justify-content: center;
  }
  /* LINKS */
    .section.-complex>*>a{
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 2vw;
      width: 10.3vw;
      border-radius: 1.7vw;
      box-shadow: 0 0.1vw 0.2vw 0 rgb(0,0,0,0.4);
      margin-top: 1vw;
      margin-bottom: 1vw;
      padding-left: 1vw;
      padding-right: 1vw;
      font-size: 0.9vw;
      transition: 0.2s;
      --local-background-color: white;
      --local-color: #98984B;
      background-color: var(--local-background-color);
      color: var(--local-color);
      cursor: pointer;
    }
    .section.-complex>*>a:hover{
      box-shadow: 0 0vw 0.1vw 0 rgb(0,0,0,0.2);
    }
    .section.-complex>*>a::after{
      content:"❯";
    }

    .section.-complex>*>a:nth-child(2n){
      --local-background-color: #ffffff;
      margin-left: 24px;
      color: #98984B !important;
      --local-color: #98984B;
    }
  /* */
  /* TEXT */
    .section.-complex>* span.number{
      font-size: 1.5em;
      color: #98984B;
    }
    .section.-complex>* em{
      font-size: 1.5em;
      color: #ffffff;
      font-style: normal;
      font-weight: bold;
    }
  /* */
  /* TEXT DEHORS */
    .section.-complex>*>p{
      font-size: pxToVw(20px, 1920px);
      padding-left: 2vw;
      padding-right: 3vw;
    }
  /* */
  /* TABLE */
    .section.-complex>*>table{
      display: flex;
      flex-direction: column;
    }
    .section.-complex>*>table>*>tr{
      display: flex;
      flex-direction: row;
      border: none;
    }
    .section.-complex>*>table>thead{
      margin-bottom: 3.7vw;
    }
    .section.-complex>*>table>*>tr>*{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-size: 1vw;
      line-height: 2em;
    }
    .section.-complex>*>table>*>tr>*>img{
      height: 4.8vw;
    }
    .section.-complex>*>table>*>tr>*>*{
      text-align: center;
      margin: 0;
      margin-bottom:0.2vw;
    }
    .section.-complex>*>table>*>tr>*>p{
      line-height: 1.1em;
    }
    .section.-complex>*>table>thead>tr>th{
      background-color: #F1F1E7;
      border-radius: 2.5vw;
      height: 7.9vw;
      box-shadow: 0 0.6vw 0.8vw 0 rgb(0,0,0,0.2);
    }
    .section.-complex>*>table>tbody>tr>td{
      width: 17.1vw;
      padding: 0;
      padding-left: 4vw;
      padding-right: 4vw;
    }
    .section.-complex>*>table>tbody>tr>td{
      position: relative;
    }
    .section.-complex>*>table>tbody>tr>td:not(:last-child)::after{
      content:"";
      display: block;
      border-right: #98984B solid 1px;
      position: absolute;
      top: 0;
      left: 0;
      width: 17.1vw;
      height: 6.1vw;
    }
    .section.-complex>*>table>thead>tr>th{
      background-color: #F1F1E7;
      border-radius: 2.5vw;
      height: 7.9vw;
      box-shadow: 0 0.6vw 0.8vw 0 rgb(0,0,0,0.2);
      width: 15.1vw;
      margin: 1vw;
    }

  /* */
/* */

/* */

/* OBJECTS */
/* Image Text Object ( object -imagetext )
  Une zone de texte faite pour être posée sur un font d'image.
  @extend Section(section)
  @accept (h4),(h5),(a)
*/
  .object.-imagetext{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding: 4.1vw;
    padding-bottom: 3vw;
    color: white;
  }
  .object.-imagetext>*{
    margin: 0;
  }
  .object.-imagetext>h4{
    font-size: 1.5vw;
    font-weight: bold;
  }
  .object.-imagetext>p{
    font-size: 1.2vw;
    width: 24.2vw;
    padding-bottom: 0.5vw;
  }
  .object.-imagetext>a{
    display: flex;

    align-items: center;
    white-space: nowrap;

    overflow: hidden;
    background-color: white;
    color: #98984B;

    border-radius: 1.7vw;
    height: 3.4vw;
    width: 3.4vw;
    padding: 0.5vw;
    font-size: 1.2vw;

    transition: 0.5s;

    cursor: pointer;
  }
  .object.-imagetext>a:hover{
    width: 12.6vw;
  }
  .object.-imagetext>a::before{
    content:"❯";
    padding-left: 0.85vw;
    padding-right: 2vw;
    font-size: 1.6vw;
    transition: 0.5s;
  }
  .object.-imagetext>a:hover::before{
    padding-right: 0.9vw;
  }
/* */
/* Right Side ( object -rightside )
  Se pose sur la droite de l'écran
  @extend Section(section)
*/
  .object.-rightside{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100;
    height: 0;
    width: 100%;
    position: relative;
  }
  .object.-rightside>*{
    position: relative;
    bottom: -14vw;
    left: -15vw;
    right: 0;
    transition: 1s;
  }
  .object.-rightside>*:not(:hover){
    transition: 1s;
  }
/* */
/* Small Text Object ( object -smalltext )
  Une zone de texte faite pour être posée sur un font d'image.
  @extend Section(section)
  @accept (h4),(h5),(a)
*/
  .object.-smalltext{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
  }
  .object.-smalltext>img{
    display: block;
    height: 6vw;
  }
  .object.-smalltext>p{
    height: 12vw;
    font-size: 1vw;
    font-style: italic;
    text-align: center;
    width: 15vw;
  }
  .object.-smalltext>span{
    color: #E0DDC9;
    font-weight: bold;
  }
  .object.-smalltext>span.name{
    font-size: 1.2vw;
  }
  .object.-smalltext>span.role{
    font-size: 1vw;
    text-decoration: underline;
  }
/* */
/* */

//style encart tempoaraire

